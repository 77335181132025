import { useCallback } from 'react';
import {
  Button,
  Icon,
  Modal,
  Select,
  Input,
  FormControl,
  Switch,
  HStack,
  VStack,
  useTheme,
} from 'native-base';
import type { Control } from 'react-hook-form';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { getRatioBetweenDimensions } from '@sizeup/dimensionConversion';
import type { PurchaseItemType, IngredientNameType } from '@sizeup/types';

const validationSchema = z.object({
  ingredientNameAndDimension: z.string(),
  saveNameForFuturePurchases: z.boolean(),
  proportion: z.string().optional(),
});

type FormSchema = z.infer<typeof validationSchema>;

interface Props {
  purchaseItem: PurchaseItemType;
  allIngredientNames: IngredientNameType[];
  onSave: (values: {
    ingredientName: string;
    dimension: string;
    proportion?: number;
    saveNameForFuturePurchases: boolean;
  }) => void;
  onCancel: () => void;
}

export function PickIngredientForm({
  purchaseItem,
  allIngredientNames,
  onSave,
  onCancel,
}: Props) {
  const { control, handleSubmit } = useForm<FormSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ingredientNameAndDimension: '',
      saveNameForFuturePurchases: true,
    },
  });
  const incompatibleProportions = useIncompatibleProportions({
    control,
    purchaseItem,
  });
  const handleSaveSuggestion = useCallback(
    ({
      ingredientNameAndDimension,
      proportion: proportionText,
      saveNameForFuturePurchases,
    }: FormSchema) => {
      const [name, dimension] = ingredientNameAndDimension.split(',');
      const proportion =
        typeof proportionText === 'string' ? Number(proportionText) : undefined;
      onSave({
        ingredientName: name,
        dimension,
        proportion,
        saveNameForFuturePurchases,
      });
    },
    [onSave]
  );
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <>
      <Modal.Body px={7}>
        <FormControl>
          <VStack space={4}>
            <VStack>
              <FormControl.Label>Escolha um ingrediente</FormControl.Label>
              <Controller
                name="ingredientNameAndDimension"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      selectedValue={value}
                      minWidth="200"
                      accessibilityLabel="Escolha um ingrediente"
                      placeholder="Escolha um ingrediente"
                      onValueChange={onChange}
                      dropdownIcon={<></>}
                    >
                      {allIngredientNames.map(({ name, dimension }) => {
                        const label = `${name} (${dimension})`;
                        return (
                          <Select.Item
                            key={[name, dimension].join(',')}
                            label={label}
                            accessibilityLabel={label}
                            value={[name, dimension].join(',')}
                          />
                        );
                      })}
                    </Select>
                    {error && (
                      <FormControl.ErrorMessage>
                        {error}
                      </FormControl.ErrorMessage>
                    )}
                  </>
                )}
              />
            </VStack>
            {incompatibleProportions ? (
              <VStack>
                <FormControl.Label>
                  Qual a proporção entre {incompatibleProportions[0]} e{' '}
                  {incompatibleProportions[1]}?
                </FormControl.Label>
                <Controller
                  name="proportion"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <Input
                        size="md"
                        placeholder="Ex.: 0.001"
                        accessibilityLabel="Qual a proporção entre bottle e ml?"
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={
                          typeof value !== 'undefined' ? String(value) : ''
                        }
                      />
                      {error && (
                        <FormControl.ErrorMessage>
                          {error}
                        </FormControl.ErrorMessage>
                      )}
                    </>
                  )}
                />
              </VStack>
            ) : null}
            <HStack alignItems="center">
              <FormControl.Label flex={1}>
                Lembrar dessa conciliação em compras futuras
              </FormControl.Label>
              <Controller
                name="saveNameForFuturePurchases"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Switch
                      accessibilityLabel="Lembrar dessa conciliação em compras futuras"
                      isChecked={value}
                      onToggle={onChange}
                      size="md"
                    />
                    {error && (
                      <FormControl.ErrorMessage>
                        {error}
                      </FormControl.ErrorMessage>
                    )}
                  </>
                )}
              />
            </HStack>
          </VStack>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group space={2}>
          <Button
            variant="outline"
            _text={{ _light: { color: 'black' }, _dark: { color: 'white' } }}
            onPress={onCancel}
          >
            Cancelar
          </Button>
          <Button
            leftIcon={
              <Icon
                as={MaterialIcons}
                name="save"
                {...IconTheme.defaultProps}
              />
            }
            onPress={handleSubmit(handleSaveSuggestion)}
          >
            Conciliar
          </Button>
        </Button.Group>
      </Modal.Footer>
    </>
  );
}

function useIncompatibleProportions({
  control,
  purchaseItem,
}: Pick<Props, 'purchaseItem'> & { control: Control<FormSchema> }) {
  const value = useWatch({ control, name: 'ingredientNameAndDimension' });
  const fromDimension = purchaseItem.dimension;
  const [, toDimension] = value.split(',');
  if (!toDimension) {
    return undefined;
  }
  const isCompatible = Boolean(
    getRatioBetweenDimensions(fromDimension, toDimension)
  );
  if (isCompatible) {
    return undefined;
  } else {
    return [fromDimension, toDimension];
  }
}
