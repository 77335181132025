import { useCallback } from 'react';
import { Button, Icon, Heading, VStack, useTheme } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface PurchasesActionsProps {
  isEmptyState?: boolean;
}

export function ProductsActions({
  isEmptyState = false,
}: PurchasesActionsProps) {
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  const { navigate } = useNavigation();
  const handleAddProductOnPress = useCallback(async () => {
    navigate('ProductCreate');
  }, [navigate]);
  return (
    <VStack
      safeAreaBottom
      roundedTop={20}
      space={4}
      p={8}
      _light={{ bg: 'accent.menuBg' }}
    >
      {isEmptyState && (
        <Heading textAlign="center" color="white" mb={2}>
          Vamos adicionar {'\n'}seu primeiro produto?
        </Heading>
      )}
      <Button
        leftIcon={
          <Icon as={MaterialIcons} name="add" {...IconTheme.defaultProps} />
        }
        onPress={handleAddProductOnPress}
      >
        novo produto
      </Button>
    </VStack>
  );
}
