import { useRef, useEffect } from 'react';
import type { ReactNode } from 'react';
import { Input, FormControl, VStack, Select, useTheme } from 'native-base';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button, Modal, Text, Icon } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { useProducts } from '../hooks';
import * as formUtils from '../../../utils/forms';

export const validationSchema = z.object({
  subproductId: formUtils.notEmptyString(),
  quantity: formUtils.stringAsNumber(),
});

type InputSchema = z.input<typeof validationSchema>;
type FormSchema = z.output<typeof validationSchema>;

export function SubproductForm({
  productId,
  title,
  visible,
  defaultValues,
  onSave,
  onClose,
  deleteButton,
  subproductFieldDisabled,
}: {
  productId: string;
  title: string;
  visible: boolean;
  defaultValues: InputSchema;
  onSave: (values: FormSchema) => void;
  onClose: () => void;
  deleteButton?: ReactNode;
  subproductFieldDisabled?: boolean;
}) {
  const productChoices = useProductChoices(productId);
  const initialRef = useRef();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<InputSchema, unknown, FormSchema>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, visible]);
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <Modal
      accessibilityRole="none"
      accessibilityLabel={title}
      accessibilityState={{ expanded: visible }}
      isOpen={visible}
      onClose={onClose}
      initialFocusRef={initialRef}
      size="lg"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>
          <Text>{title}</Text>
        </Modal.Header>
        <Modal.Body>
          <VStack space={4}>
            <VStack>
              <FormControl.Label>Escolha um produto</FormControl.Label>
              <Controller
                name="subproductId"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      ref={subproductFieldDisabled ? undefined : initialRef}
                      selectedValue={value}
                      minWidth="200"
                      accessibilityLabel="Subproduto"
                      placeholder="Escolha um produto"
                      onValueChange={onChange}
                      dropdownIcon={<></>}
                      isDisabled={!productChoices || subproductFieldDisabled}
                    >
                      {(productChoices ?? []).map(({ id, name }) => {
                        return (
                          <Select.Item
                            key={id}
                            label={name}
                            accessibilityLabel={name}
                            value={id}
                            testID={`product-choice-${id}`}
                          />
                        );
                      })}
                    </Select>
                    {error && (
                      <FormControl.ErrorMessage>
                        {error}
                      </FormControl.ErrorMessage>
                    )}
                  </>
                )}
              />
            </VStack>
            <VStack>
              <Controller
                name="quantity"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <FormControl.Label>Quantidade</FormControl.Label>
                    <Input
                      ref={subproductFieldDisabled ? initialRef : undefined}
                      size="md"
                      placeholder="Ex.: 0.001"
                      accessibilityLabel="Quantidade"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={typeof value !== 'undefined' ? String(value) : ''}
                    />
                    {error && (
                      <FormControl.ErrorMessage>
                        {error.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </VStack>
          </VStack>
        </Modal.Body>
        <Modal.Footer
          flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button.Group space={2}>
            <Button
              variant="outline"
              _text={{ _light: { color: 'black' }, _dark: { color: 'white' } }}
              onPress={onClose}
            >
              Cancelar
            </Button>
            <Button
              leftIcon={
                <Icon
                  as={MaterialIcons}
                  name="save"
                  {...IconTheme.defaultProps}
                />
              }
              onPress={handleSubmit(onSave)}
              isDisabled={!isValid || !isDirty}
            >
              Salvar Subproduto
            </Button>
          </Button.Group>
          {deleteButton}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

function useProductChoices(productId: string) {
  const { products } = useProducts();
  if (!products) {
    return undefined;
  }
  return products
    .map((product) => ({
      id: product.productId,
      name: product.recipeYieldDimension
        ? `${product.name} (${product.recipeYieldDimension})`
        : product.name,
    }))
    .filter((item) => item.id !== productId);
}
