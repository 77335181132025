import {
  Box,
  Button,
  Icon,
  VStack,
  useMediaQuery,
  useTheme,
} from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface ProductFormActionsProps {
  handleSave: () => void;
  isDisabled?: boolean;
}

export function ProductFormActions({
  handleSave,
  isDisabled = false,
}: ProductFormActionsProps) {
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  const [isPortrait] = useMediaQuery([
    {
      orientation: 'portrait',
    },
  ]);
  return (
    <VStack safeAreaBottom backgroundColor="white" shadow={10} space={4}>
      <Box p={8} w={isPortrait ? '100%' : '50%'}>
        <Button
          testID={`product-form-submit${isDisabled ? '-disabled' : ''}`}
          isDisabled={isDisabled}
          leftIcon={
            <Icon as={MaterialIcons} name="save" {...IconTheme.defaultProps} />
          }
          onPress={handleSave}
        >
          Salvar receita
        </Button>
      </Box>
    </VStack>
  );
}
