import { useState, useCallback } from 'react';
import { Button, Icon, useTheme } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface Props {
  onClick: () => Promise<void>;
  title?: string;
}

export function DeleteButton({ onClick, title = 'Remover' }: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    await onClick();
    setIsDeleting(false);
  }, [onClick]);
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  const color = 'error.600';
  return (
    <Button
      variant="outline"
      colorScheme="error"
      _text={{ _light: { color }, _dark: { color } }}
      onPress={handleDelete}
      isDisabled={isDeleting}
      isLoading={isDeleting}
      leftIcon={
        <Icon
          as={MaterialIcons}
          name="delete"
          {...IconTheme.defaultProps}
          size="md"
          color={color}
        />
      }
    >
      {title}
    </Button>
  );
}
