import { Button, Icon, VStack, useTheme } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface AddPurchaseItemActionsProps {
  handleSaveAddAnother: () => void;
  handleSaveReturn: () => void;
  isDisabled: boolean;
}

export function AddPurchaseItemActions({
  handleSaveAddAnother,
  handleSaveReturn,
  isDisabled,
}: AddPurchaseItemActionsProps) {
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <VStack safeAreaBottom backgroundColor="white" shadow={10} space={4} p={8}>
      <Button
        leftIcon={
          <Icon as={MaterialIcons} name="save" {...IconTheme.defaultProps} />
        }
        onPress={handleSaveAddAnother}
        isDisabled={isDisabled}
      >
        Salvar item e adicionar outro
      </Button>
      <Button
        leftIcon={
          <Icon as={MaterialIcons} name="list" {...IconTheme.defaultProps} />
        }
        onPress={handleSaveReturn}
        isDisabled={isDisabled}
      >
        Salvar item e retornar
      </Button>
    </VStack>
  );
}
