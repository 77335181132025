import { ComponentProps, useMemo } from 'react';
import { Box, Center, Pressable, Heading, Icon } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface BlockButtonProps {
  title: string;
  selected?: boolean;
  icon?: React.ReactNode | string;
  onPress?: () => void;
  accessibilityRole?: ComponentProps<typeof Pressable>['accessibilityRole'];
}

const pressedStyle = {
  style: {
    transform: [
      {
        scale: 0.95,
      },
    ],
  },
};

export function BlockButton({
  title,
  selected,
  icon,
  onPress,
  accessibilityRole,
}: BlockButtonProps) {
  const IconRender = useMemo(() => {
    return typeof icon === 'string' ? (
      <Icon
        as={MaterialIcons}
        name={icon}
        size={10}
        _light={{ color: 'black' }}
        _dark={{ color: 'white' }}
      />
    ) : (
      icon
    );
  }, [icon]);

  return (
    <Pressable
      onPress={onPress}
      _pressed={pressedStyle}
      accessibilityRole={accessibilityRole}
    >
      {({ isHovered, isPressed }) => {
        return (
          <Box
            alignItems="center"
            justifyContent="center"
            w="120px"
            h="130px"
            backgroundColor={selected ? 'muted.200' : 'transparent'}
          >
            <Box
              w="80px"
              h="85px"
              bg={
                isPressed ? 'muted.200' : isHovered ? 'muted.100' : 'muted.50'
              }
              rounded="20"
              shadow={3}
              borderWidth="1"
              borderColor="muted.300"
              justifyContent="center"
              alignItems="center"
            >
              {icon && <Center>{IconRender}</Center>}
            </Box>
            <Heading
              textAlign="center"
              _light={{ color: 'muted.900' }}
              _dark={{ color: 'muted.50' }}
              mt="3"
              size="xs"
            >
              {title}
            </Heading>
          </Box>
        );
      }}
    </Pressable>
  );
}
