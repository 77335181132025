const colors = {
  primary: {
    50: '#E8FFF8',
    100: '#BEFFEB',
    200: '#94FFDF',
    300: '#6AFFD3',
    400: '#41FFC6',
    500: '#15ECAC',
    600: '#00C389',
    700: '#009A6C',
    800: '#007150',
    900: '#004933',
  },
  secondary: {
    50: '#F7F0FF',
    100: '#E4CFFF',
    200: '#D2ADFF',
    300: '#BF8CFF',
    400: '#AD6AFF',
    500: '#9151DF',
    600: '#753BBD',
    700: '#5B289B',
    800: '#441979',
    900: '#2E0D57',
  },
  accent: {
    600: '#00C389',
    700: '#009A6C',
    800: '#007150',
    menuBg: '#F7CED7',
  },
  mutedLight: {
    600: '#e5e5e5',
    700: '#d4d4d4',
    800: '#a3a3a3',
    900: '#737373',
  },
  light: {
    50: '#ffffff',
  },
};

const theme = {
  config: {
    initialColorMode: 'light', // TODO: Should be dynamic
  },
  colors,
  fontConfig: {
    Encode: {
      // normal
      400: {
        normal: 'EncodeSans-Regular',
      },
      // medium
      500: {
        normal: 'EncodeSans-Medium',
      },
      // bold
      700: {
        normal: 'EncodeSans-Bold',
      },
    },
    Baloo2: {
      700: {
        normal: 'Baloo2-Bold',
      },
    },
  },

  fonts: {
    heading: 'Baloo2',
    body: 'Encode',
  },

  shadows: {
    '10': {
      'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
  },

  components: {
    Text: {
      defaultProps: {
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: '400',
        fontStyle: 'normal',
        _light: { color: 'text.900' },
        _dark: { color: 'text.50' },
      },
    },
    Heading: {
      defaultProps: {
        size: 'md',
        fontFamily: 'heading',
        fontWeight: '700',
        fontStyle: 'normal',
        _light: { color: 'text.900' },
        _dark: { color: 'text.50' },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: 'secondary',
        size: 'md',
        _text: {
          _light: { color: 'text.50' },
          _dark: { color: 'text.50' },
          fontSize: 'md',
          fontWeight: 'medium',
          fontFamily: 'body',
        },
      },
    },
    Icon: {
      defaultProps: {
        color: 'white',
        size: 'lg',
      },
    },
    FormControlLabel: {
      defaultProps: {
        _text: {
          _light: { color: 'text.900' },
          _dark: { color: 'text.50' },
          fontWeight: 'bold',
          fontSize: 'md',
          fontFamily: 'body',
        },
      },
    },
    Select: {
      defaultProps: {
        size: 'lg',
      },
    },
    Input: {
      defaultProps: {
        size: 'lg',
      },
    },
  },
};

const LogoImage = require('../assets/sizeup/logo.png'); // eslint-disable-line @typescript-eslint/no-var-requires
const LogoImageAuth = require('../assets/sizeup/logoAuth.png'); // eslint-disable-line @typescript-eslint/no-var-requires

export default { theme, LogoImage, LogoImageAuth };
