import { Button, Icon, useTheme } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {
  useInvoiceUpload,
  InvoiceUploadStage,
} from '../hooks/useInvoiceUpload';

export default function InvoiceUpload() {
  const { stage, onUpload } = useInvoiceUpload();
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <Button
      onPress={onUpload}
      isDisabled={stage !== InvoiceUploadStage.Waiting}
      isLoading={stage === InvoiceUploadStage.Processing}
      isLoadingText="processando arquivo..."
      colorScheme={getColorScheme(stage)}
      accessibilityLabel="Upload an invoice file"
      leftIcon={
        <Icon
          as={MaterialIcons}
          name="file-upload"
          {...IconTheme.defaultProps}
        />
      }
    >
      {getInvoiceUploadLabel(stage)}
    </Button>
  );
}

function getColorScheme(stage: InvoiceUploadStage) {
  if (stage === InvoiceUploadStage.Done) {
    return 'success';
  }
  if (stage === InvoiceUploadStage.Error) {
    return 'danger';
  }
  if (stage === InvoiceUploadStage.Processing) {
    return 'light';
  }
  return 'secondary';
}

function getInvoiceUploadLabel(stage: InvoiceUploadStage) {
  if (stage === InvoiceUploadStage.Waiting) {
    return <>enviar nota fiscal</>;
  }
  if (stage === InvoiceUploadStage.Processing) {
    return <>processando arquivo...</>;
  }
  if (stage === InvoiceUploadStage.Error) {
    return <>error ao processar</>;
  }
  return <>importação concluída!</>;
}
