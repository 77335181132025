import { Button, Icon, Heading, VStack, useTheme } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import InvoiceUpload from './InvoiceUpload';

interface PurchasesActionsProps {
  isEmptyState?: boolean;
  handleAddManualPurchaseOnPress: () => void;
}

export function PurchasesActions({
  isEmptyState = false,
  handleAddManualPurchaseOnPress,
}: PurchasesActionsProps) {
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <VStack
      safeAreaBottom
      roundedTop={20}
      backgroundColor="secondary.700"
      space={4}
      p={8}
      _light={{ bg: 'accent.menuBg' }}
    >
      {isEmptyState && (
        <Heading textAlign="center" mb={2}>
          Vamos adicionar {'\n'}sua primeria compra?
        </Heading>
      )}
      <Button
        leftIcon={
          <Icon as={MaterialIcons} name="add" {...IconTheme.defaultProps} />
        }
        onPress={handleAddManualPurchaseOnPress}
      >
        compra manual
      </Button>
      <InvoiceUpload />
    </VStack>
  );
}
